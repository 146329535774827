import { CurrencyName } from '~/constants/currency.constants';
import { useUserStore } from '~/store/user/user.store';
import { GlobalUtils } from '~/utils';

type TFormatCurrencyArgs = Parameters<typeof GlobalUtils.Currency.formatCurrency>;

/** Форматирование валюты для определенной группы пользователей **/
export function useFormatCurrency(...args: TFormatCurrencyArgs) {
  const userStore = useUserStore();
  /** Если пользователь isFake, то вместо знака валюты врозвращаем иконку алмаза **/
  if (userStore.user?.isFake) {
    const value = args[0];
    return GlobalUtils.Currency.formatCurrency(value, CurrencyName.DIAMOND);
  }
  /** Если пользователь !isFake, то просто вызываем GlobalUtils.Currency.formatCurrency с переданными аргументами. **/
  return GlobalUtils.Currency.formatCurrency(...args);
}
